<template>
  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <b-card v-if="form != null">


        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-row>

          <b-col md="12">
              
              <h4 class="card-title float-left"> {{form.form_name | capitalize }}</h4>
              
          </b-col>
          
        </b-row>

        <b-row class="form_detail">

          <!-- <b-col md="3">
              
              <b-form-group
                  label="Crew"
                  class="required"

                >
                  <b-form-select v-model="crew">

                    <b-form-select-option value="">Select</b-form-select-option>
                    <b-form-select-option :value="c._id" v-for="c in allCrew" :key="c._id">{{c.full_name}}</b-form-select-option>
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col> -->

          <b-col md="3" >
              
              <b-form-group
                  label="Location"
                  class="required"

                >
                  <b-form-select v-model="location_id">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="l._id" v-for="l in allLocations" :key="l._id">{{l.name | capitalize}}</b-form-select-option>
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col>

          <b-col md="3">
              
              <b-form-group
                  label="Inspected By"
                  class="required"

                >
                  <b-form-input
                    placeholder="3 to 50 characters"
                    v-model="supervisor_name"
                  />
                    
                </b-form-group>
              
          </b-col>

          <b-col md="3">
              
              <b-form-group
                  label="Accompanied By"
                  class=""

                >
                  <b-form-input
                    placeholder="3 to 50 characters"
                    v-model="accompanied_by"
                    autocomplete="off"
                  />
                    
                </b-form-group>
              
          </b-col>

          <b-col md="3">
              
              <b-form-group label="Inspection Date Time" class="mobile_tab_margin_bottom_zero required">
                  <date-picker v-model="inspection_date_time" 
                    format="DD MMM YYYY HH:mm" 
                    type="datetime"
                    placeholder="Select"
                    valueType="format"
                    :disabled-date="disabledDate"
                    :disabled-time="disabledTime"
                    :clearable=false
                    :confirm=true
                    lang="en"
                   
                    :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
                    ></date-picker>
                </b-form-group>
                
            </b-col>

          <b-col md="3">
              
              <b-form-group
                  label="Total Score"
                  class="mobile_tab_margin_bottom_zero mt-1"

                >
                  <b-form-input
                    readonly
                    v-model="total_score"
                  />
                    
                </b-form-group>
              
          </b-col>
          
          
          
        </b-row> 
    
    </b-card>

    <div v-if="item != null">


      <b-card>
      
      <b-form @submit="formSubmit">

        <b-row class="mb-1">
            <b-col md="1">
              <h5> # </h5>
            </b-col>
            <b-col md="2">
              <h5> Unit Name </h5>
            </b-col>
            <b-col md="1">
             <h5> % </h5>
            </b-col>
            <b-col md="1">
              <h5> S.No </h5>
            </b-col>
            <b-col md="2" v-if="form.site.no_photo_reporting == 'no'">
              <h5> Element Name </h5>
            </b-col>

            <b-col md="3" v-if="form.site.no_photo_reporting == 'yes'">
              <h5> Element Name </h5>
            </b-col>

            
            <b-col md="2">
              <h5> Rating </h5>
            </b-col>

            <b-col md="2">
              <h5> Comment </h5>
            </b-col>
            <b-col md="1" v-if="form.site.no_photo_reporting == 'no'">
              <h5> Image </h5>
            </b-col>

            
        </b-row>
        <hr>
        <div v-for="(qi,index) in item">

          <b-row class="mb-0">

              <b-col md="1" class="mb-1">
                {{index + 1}}
              </b-col>

              <b-col md="2" class="mb-1">
                <p class="wordBreak"> {{qi.unit_name}} </p>
              </b-col>
              <b-col md="1" class="mb-1">
                {{qi.unit_rating |currency}}
              </b-col>

              
              <b-col md="1" class="mb-1">
                1
              </b-col>
              <b-col md="2" class="mb-1" v-if="form.site.no_photo_reporting == 'no'">
                <p class="wordBreak">{{qi.elements[0].name}} </p>
              </b-col>

              <b-col md="3" class="mb-1" v-if="form.site.no_photo_reporting == 'yes'">
                <p class="wordBreak">{{qi.elements[0].name}} </p>
              </b-col>
              <!-- <b-col md="2" class="mb-1" v-if="edit == 'no'">
                {{qi.elements[0].rating |currency}}
              </b-col> -->
              <b-col md="2" class="mb-1">
                
                  <b-form-select v-model="qi.elements[0].rating" @change="calRating(index,0)">

                    <b-form-select-option value="0">N/A</b-form-select-option>
                    
                    <b-form-select-option :value="m" v-for="m in max" :key="m">{{m}}</b-form-select-option>
                    
                    
                  </b-form-select>
              </b-col>

              <b-col md="2" class="mb-1">
                
                  <b-form-input
                    autocomplete="off"
                    v-model="qi.elements[0].comment"
                  />

              </b-col>

              <b-col md="1" class="mb-1" v-if="form.site.no_photo_reporting == 'no'">
                
                  <b-media @click="$refs['biInput' +index + 0 ][0].click()">
                      <template #aside>
                        <b-avatar
                          :ref="'bI' + index + 0"
                          :src="qi.elements[0].image.image != null ? qi.elements[0].image.image : doc_icon"
                          variant="light-warning"
                          size="40px"
                          rounded
                        />
                      </template>

                      <div class="d-flex flex-wrap">
                        <input
                          :ref="'biInput' + index + 0"
                          type="file"
                          class="d-none"
                          @input="biInput($event,index,0)"
                        >
                      </div>

                      
                    </b-media>

                    
                    
                  
              </b-col>

              


          </b-row>

          <b-row class="mb-0" v-for="(element,k) in qi.elements" v-if="qi.elements.length > 1" :key="k">
              <b-col md="1" v-if="k > 0" class="mb-3">
                
              </b-col>
              <b-col md="2" v-if="k > 0" class="mb-3">
                
              </b-col>
              <b-col md="1" v-if="k > 0" class="mb-3">
                
              </b-col>
              <b-col md="1" v-if="k > 0" class="mb-3">
                {{k + 1}}
              </b-col>
              <b-col md="2" v-if="k > 0 && form.site.no_photo_reporting == 'no'" class="mb-3">
                {{element.name}}
              </b-col>

              <b-col md="3" v-if="k > 0 && form.site.no_photo_reporting == 'yes'" class="mb-3">
                {{element.name}}
              </b-col>

              <!-- <b-col md="2" v-if="k > 0 && edit == 'no'" class="mb-3">
                {{element.rating |currency}}
              </b-col> -->

              <b-col md="2" v-if="k > 0 " class="mb-3">
                <b-form-select v-model="element.rating" @change="calRating(index,k)">

                    <b-form-select-option value="0">N/A</b-form-select-option>
                    
                    <b-form-select-option :value="m" v-for="m in max" :key="m">{{m}}</b-form-select-option>
                    
                    
                  </b-form-select>
              </b-col>
              <b-col md="2" v-if="k > 0" class="mb-3" >
                <b-form-input
                    autocomplete="off"
                    v-model="element.comment"
                  />
              </b-col>
              <b-col md="1" v-if="k > 0 && form.site.no_photo_reporting == 'no'" class="mb-3">
                  <b-media @click="$refs['biInput' +index + k ][0].click()">
                      <template #aside>
                        <b-avatar
                          :ref="'bI' + index + k"
                          :src="element.image.image != null ? element.image.image : doc_icon"
                          variant="light-warning"
                          size="40px"
                          rounded
                        />
                      </template>

                      <div class="d-flex flex-wrap">
                        <input
                          :ref="'biInput' + index + k"
                          type="file"
                          class="d-none"
                          @input="biInput($event,index,k)"
                        >
                      </div>

                      
                    </b-media>

                    
              </b-col>

              
          </b-row>
          <hr>
        </div>

        <br>


        <b-row>
          <b-col md="12">
              
              <b-form-group
                  label="Closing Report"
                  class=""

                >
                  <b-form-textarea
                    
                    v-model="note"
                  />
                    
                </b-form-group>
              
          </b-col>
        </b-row>
        

        <!-- <b-row>
                
            <b-col
            cols="12"
            class="mb-2 mt-2"
            >
              <h4 class="mb-0">
                Upload Images
              </h4>

              
            </b-col>
        </b-row> -->

        <!-- <b-row>

          <b-col
            cols="10"
            class="mb-2 mt-2"
          > 
            <b-row>
              <b-col md="3" v-for="(bi,index) in images" :key="index">
                    <b-media class="mb-2 mt-2" @click="$refs['biInput' +index ][0].click()">
                      <template #aside>
                        <b-avatar
                          :ref="'bI' + index"
                          :src="bi.image != null ? bi.image : doc_icon"
                          variant="light-warning"
                          size="200px"
                          rounded
                        />
                      </template>

                      <div class="d-flex flex-wrap">
                        <input
                          :ref="'biInput' + index"
                          type="file"
                          class="d-none"
                          @input="biInput($event,index)"
                        >
                      </div>

                      
                    </b-media>


                    
                    <b-button
                        variant="danger"
                        class="mt-0 ml-5 mr-0"
                        @click="removeImage(index)"
                      >
                      <feather-icon icon="XIcon" />
                      
                    </b-button>

                    
              </b-col>
              
              

            </b-row>
          </b-col>

          <b-col md="2" >
            <b-button
                variant="warning"
                class="mt-2 ml-50 mr-0 float-right"
                @click="addImage()"
                v-if="images.length < 20"
              >
              <feather-icon icon="PlusCircleIcon" />
              <span class="align-middle ml-50">Add More</span>
            </b-button>
          </b-col>
        </b-row> -->

        <b-row>

          <!--<b-col
            cols="5"
            class="mb-2 mt-2"
          > 
          </b-col>-->
          <b-col
            cols="12"
            class="mb-2 mt-2"
          > 

            
              
                <b-col
                class="mb-2 mobile_pdng_lft_rgt_zero"
                ><center>
                  <h4 class="mb-0 align_start_mobile">
                    Signature
                  </h4>
                </center>
                  
                </b-col>
           
            

            
                <b-col
                  class="mb-2 mt-0 mobile_pdng_lft_rgt_zero"
                  style="text-align: center"
                > 
              
                    <b-media class="mb-2 mt-2 desktop_display_block" @click="openSignature('supervisor_signature')" v-if="supervisorSign.image == null">
                      <template #aside>
                        <b-avatar
                          ref="supervisorS"
                          :src="doc_icon"
                          variant="light-danger"
                          size="200px"
                          rounded
                        />
                      </template>

                      <div class="d-flex flex-wrap">
                        <input
                          ref="supervisorSInput"
                          type="file"
                          class="d-none"
                          @input="supervisorImageUpload"
                        >
                      </div>

                      
                    </b-media>

                    <img :src="supervisorSign.image" width="200px" height="180" v-if="supervisorSign.image != null" style="background: #ffffff">

                    
                    <center class="single_img_danger_btn">
                      <b-button
                          variant="danger"
                          class="mt-1 ml-2"
                          v-if="supervisorSign.image != null"
                          @click="removeSign('supervisor')"
                        >
                        <feather-icon icon="XIcon" />
                        
                      </b-button>
                    </center>
                  
                  </b-col>
            
          </b-col>

          <!--<b-col
            cols="5"
            class="mb-2 mt-2"
          > 
          </b-col>-->

        </b-row>

        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="warning"
              class="mr-1"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="$router.push({ name:'update-qi' })"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>

      </b-form>
      </b-card>


      <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="3">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col md="3">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col md="3">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col md="3">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal>

    </div>



  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BBreadcrumb
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from "moment-timezone";
// var moment = require('moment-timezone');
export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BBreadcrumb,DatePicker
  },
  
  data() {
    return {
      inspection_date_time: moment().format('DD MMM YYYY HH:mm'),
      item:null,
      showDismissibleAlert:false,
      form:null,
      max:0,
      crew:'',
      allCrew:[],
      note:'',
      location_id:'',
      allLocations:[],
      total_score:0,
      supervisor_name:this.$store.getters.currentUser.full_name,
      accompanied_by:'',
      images:[{
        image:null,
        name:'',
      }],

      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      inspectedBySign : {
        image:null,
        name:'',
        type: 'inspected_by',
        default:'no'
      },
      supervisorSign : {
        image:null,
        name:'',
        type: 'supervisor',
        default:'no'
      },
      openedSignModel:null,
      edit:'no',
      tempSign : {
        image:null,
        name:'',
      },
      show_default:false,
      having_default:false,
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    disabledDate(date){
      var today = moment().tz('Asia/Singapore');
      return date >= today;
    },
    disabledTime(date, currentValue){
      return date >= moment().tz('Asia/Singapore');
    },

    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },
    
    variantColor(status){
      if (status == 'assigned') {
        return 'primary';
      }else if(status == 'in_progress'){
        return 'warning'
      }else{
        return 'success';
      }
    },

    qiDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id,
           },
           api: '/api/assigned-qi-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.item = this.$store.getters.getResults.data.qi;
                this.form = this.$store.getters.getResults.data.form;
                this.max = parseInt(this.form.site.qi_max);
                

                this.allCrewList();
                this.allLocationsList();

                return this.item;
            }
        });
    },

    removeSign(sign){


      if (sign == 'supervisor') {
          this.supervisorSign.image = null;
          this.supervisorSign.name = '';
          this.supervisorSign.default = 'no';
      }else{
          this.inspectedBySign.image = null;
          this.inspectedBySign.name = '';
          this.inspectedBySign.default = 'no';
      }

      
    },
    supervisorImageUpload(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs.supervisorSInput.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs.supervisorSInput.value=null;
              

          } else {
              reader.onload = (e) => {

                this.supervisorSign.image = e.target.result;
                this.supervisorSign.name = image.name;
                this.supervisorSign.type = 'supervisor';
                this.supervisorSign.default = 'no';

            }
          }
      }
      

    },
    inspectedImageUpload(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs.clientSInput.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs.clientSInput.value=null;
              

          } else {
              reader.onload = (e) => {

                /*this.supervisorSign.image = e.target.result;
                this.supervisorSign.name = image.name;*/
                /*var imageData = {
                  image : e.target.result,
                  name : image.name,
                  type: 'inspected'
                }*/

                this.inspectedBySign.image = e.target.result;
                this.inspectedBySign.name = image.name;
                this.inspectedBySign.type = 'inspected_by';
                this.inspectedBySign.default = 'no';
                //this.updateTaskSignature(imageData)

            }
          }
      }
    },
    calRating(i,k){
      
      var overall = 0;
      var overalltotal = 0;

       for(var l = 0; l < this.item.length; l++){
        
        var e_total = 0;
        var e = 0;

        for(var m = 0; m < this.item[l].elements.length; m++ ){
            
            if (this.item[l].elements[m].rating > 0) {
              e = e+1;
            }


            e_total = e_total + parseInt(this.item[l].elements[m].rating);

        }

        if (e > 0) {

           var total = (e_total * 100)/(e * this.max);
         }else{
           var total = 0;
         }



        //var total = (e_total * 100)/(e * this.max);

        this.item[l].unit_rating = parseFloat(total).toFixed(2);
        
        
        if (parseFloat(total).toFixed(2) > 0) {
          overalltotal = overalltotal + 1;
        }

        overall = parseFloat(overall) + parseFloat(this.item[l].unit_rating);

       }
       
       //console.log(overall, overalltotal);

       if (overalltotal > 0) {
          var overallTotal = (parseFloat(overall).toFixed(2) * 100) / (overalltotal * 100);
       
          this.total_score = overallTotal.toFixed(2); 
       }else{
          this.total_score = 0; 

       }
       

       //this.updateQData('in_progress');
    },
    
    formSubmit(e){
        e.preventDefault();
        
        return this.$store.dispatch(POST_API, {
               data:{
                 inspection_date_time : moment(this.inspection_date_time, 'DD MMM YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
                 form_id: this.form._id,
                 //crew_id:this.crew,
                 location_id:this.location_id,
                 //supervisor_name:this.supervisor_name,
                 inspected_by:this.supervisor_name,
                 qi_data:this.item,
                 note:this.note,
                 supervisor_signature:this.supervisorSign,
                 inspected_by_signature:this.inspectedBySign,
                 total_score:this.total_score,
                 images:this.images,
                 accompanied_by : this.accompanied_by,
                 no_photo_reporting : this.form.site.no_photo_reporting

               },
               api: '/api/mark-complete-aqi'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'Report Submitted Successfully.',
                      showConfirmButton: false,
                      timer: 1500
                    }).then((result) => {
                        // this.$router.go(-1);
                        this.$router.push({ name:'update-qi' })
                    });
                    
                }
            });
    },
    allCrewList(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site,
           },
           api: '/api/all-crew'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.allCrew = this.$store.getters.getResults.data;
                this.crew = this.allCrew.length > 0 ? this.allCrew[0]._id : '';
                return this.allCrew;
            }
        });
    },
    allLocationsList(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site,
             type:'QI'
           },
           api: '/api/all-locations'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
            } else {
                
                this.allLocations = this.$store.getters.getResults.data;
                return this.allLocations;
            }
        });
    },
    biInput(event,index,key){
      console.log(event,index);

      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })

              this.$refs['biInput'+index+key][0].value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs['biInput'+index+key][0].value=null;
              

          } else {
              reader.onload = (e) => {

                this.item[index].elements[key].image.image = e.target.result;
                this.item[index].elements[key].image.name = image.name;

            }
          }
      }
    },
    addImage(){
      var bi = {
        image:null,
        name:'',
      }

      this.images.push(bi);
    },
    removeImage(index){
      this.images.splice(index,1);

      if (this.images.length == 0) {
        this.images.push({
            image:null,
            name:'',
          });   
      }
    },

    useDefault(){

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser._id
           },
           api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                  
                  this.having_default = false;
                  
                }else{

                  this.tempSign = {
                    image : data.default_signature,
                    name : 'siganture.png',
                  }

                  this.having_default = true;
                }
            }
        });
    },
    uploadSign(){
      
      if (this.openedSignModel == 'supervisor_signature') {

         this.$refs['supervisorSInput'].click();
      }

      if (this.openedSignModel == 'inspected_by') {

         this.$refs['inspectedSInput'].click();
      }

      this.$refs['signatureModel'].hide();

    },
    openSignature(type){
      if (this.openedSignModel == null) {

          if (this.having_default == true) {
              this.show_default = true;
          }else{
            this.show_default = false;
          }

          this.$refs['signatureModel'].show();
          this.openedSignModel = type;
      }
    },
    hideSignature(){
      this.openedSignModel = null;
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{


        if (this.openedSignModel == 'supervisor_signature') {
          
          this.supervisorSign.image = data;
          this.supervisorSign.name = 'siganture.png';
          this.supervisorSign.type = 'supervisor';
          this.supervisorSign.default = 'no';

          
        }else{

          this.inspectedBySign.image = data;
          this.inspectedBySign.name = 'siganture.png';
          this.inspectedBySign.type = 'inspected_by';
          this.inspectedBySign.default = 'no';
          

        }

        this.$refs['signatureModel'].hide();
      }
    },
    saveDefault(){
      
      if (this.openedSignModel == 'supervisor_signature') {
                    
        this.supervisorSign = {
          image : this.tempSign.image,
          name : 'signature.png',
          type :   'supervisor',
          default:'yes'
        }
        
      }else{

        this.inspectedBySign = {
          image : this.tempSign.image,
          name : 'signature.png',
          type: 'inspected_by',
          default:'yes'
        }
      }

      this.$refs['signatureModel'].hide();
    },
    breadCrumb(){
      var item = [
      {
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'QI & Checklist'
      },{
        to:{name:'update-qi'},
        text: 'Create QI',
      },{
        to:null,
        text:"Form Detail"
      }];
      return item;
    }
  },
  mounted(){
    this.useDefault();
    this.qiDetail();

    
  }
  
  
}
</script>

<style>

</style>
